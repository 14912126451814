.dashboard {
    overflow: hidden;
    /* background: url("../../assets/images/Dashboard/registration.jpg") no-repeat center top fixed; */
    background-color: var(--red1);
    background-size: cover;
    height: 90vh;
    position: relative;
    margin: auto;
}

.col {
    display: flex;
    flex-direction: column;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.logout {
    display: none;
}

dashboard button {
    font-family: Inter;
}

#lights-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8vh;
    position: absolute;
}

#lamp {
    margin-left: 12.5vw;
    width: 34.5vw;
}

#lamp-light {
    width: 54.5vw;
    margin-left: 2.5vw;
    margin-top: -1.399vw;
    height: 100vh;
}

.main-pane {
    width: 34.5vw;
    float: left;
    margin-left: 12.5vw;
    margin-top: 8vh;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
}

#img-flowers {
    position: absolute;
    width: auto;
    max-height: 600px;
    right: 5vw;
    /* top: 320px; */
    bottom: -10px;
    z-index: 2;
    pointer-events: none;
}

#tall-flowers {
    position: absolute;
    width: auto;
    max-height: 470px;
    right: 5vw;
    /* top: 320px; */
    bottom: -100px;
    z-index: 2;
    pointer-events: none;
}

.greeting h1 {
    font-family: Eugusto;
    font-weight: 800;
    color: #444444;
    margin-top: 30px;
}

.message {
    color: #444444;
}

.app-status-message {
    color: #211959;
}

#start {
    /* width: 86%;
    height: 50px; */
    padding: 13px;
    color: #444444 !important;
    font-family: Inter;
    font-weight: 100;
    background-color: var(--red1);
}

#start .MuiButton-label {
    font-size: 120%;
}

#app-stat {
    margin-top: 30px;
    font-size: 120%;
    font-family: Inter;
}

.inner-button {
    /* width:412.84px;
  height: 52px; */
    margin-top: 10px !important;
    width: 30vw;
    height: 7vh;
    margin-bottom: 30px !important;
    font-family: Inter;
    background-color: var(--red1);
}

/* #status {
  width: 510px;
  height: 400px;
} */

.dashboard-button {
    /* width: 497px;
  height: 186px; */
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 10px;
    background-color: var(--red1);
}

.dashboard .MuiButton-label {
    font-size: 129%;
}

#reimbursement {
    /* width: 497px;
  height: 111px; */
    /* width: 34.5vw;
    height: 15vh;
    margin-top: 40px; */
    margin-top: 30px;
    background-color: var(--red1);
}

#apply-reimbursement {
    font-family: Inter;
    font-size: 20px;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 30px;
}

.button-title {
    font-size: 120%;
    font-weight: 100;
}

.sub-text {
    font-size: 110%;
    margin-left: 2vw;
    margin-right: 2vw;
}

#coming-soon {
    font-size: 90%;
}

#apply-title {
    margin-bottom: 11px;
}

@media screen and (max-width: 1024px) {

    .main-pane {
        display: flex;
        position: relative;
        justify-content: center;
    }

    .dashboard {
        background-size: cover;
        background-position: top;
    }

    .greeting h1 {
        font-size: 5.6vw;
    }

    .dashboard-button {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .greeting h1 {
        font-size: 5.6vw;
    }

    .dashboard-button {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    #app-stat {
        font-size: 165%;
    }

    .dashboard .MuiButton-label {
        font-size: 165%;
    }

    .inner-button {
        height: auto;
    }

    #apply-title {
        font-size: 165%;
    }

    @media screen and (min-height: 768px) {
        .main-pane {
            margin-top: 22vh;
        }

        .dashboard-button {
            width: 70%;
            margin-top: 2px;
        }


    }


    @media screen and (max-height: 768px) {
        #img-flowers {
            visibility: hidden;
        }

        #tall-flowers {
            visibility: hidden;
        }

    }


    @media screen and (width: 1024px) and (height: 1366px) {
        .main-pane {
            margin-top: 10vh;
        }

        .dashboard-button {
            margin-top: 40px;
        }
    }
}

@media screen and (max-width: 768px) {

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.logout {
        display: flex;
        margin-top: 15px;
        padding: 8px;
    }

    #img-flowers {
        visibility: hidden;
    }

    #tall-flowers {
        visibility: hidden;
    }

    .greeting h1 {
        font-size: 7.6vw;
    }

    .dashboard .MuiButton-label {
        font-size: 165%;
    }

    .main-pane {
        margin-top: 10vh;
    }

    .dashboard-button {
        background-color: var(--red1);
        margin-left: auto;
        margin-right: auto;
        width: 83%;
        margin-top: 40px;
    }

    #app-stat {
        font-size: 165%;
    }

    #apply-title {
        font-size: 165%;
    }

    .inner-button {
        background-color: var(--red1);
    }

    #start {
        background-color: var(--red1);
    }

    #reimbursement {
        background-color: var(--red1);
    }
}

/* iPhone 6/7/8 Plus */
@media screen and (max-width: 628px) {
    .greeting h1 {
        font-size: 30px;
    }

    .button-title {
        font-size: 20px;
    }

    .main-pane {
        width: 75%;
    }

    .dashboard-button {
        width: 100%;
        float: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }

    #app-stat {
        font-size: 5.5vw;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    #apply-title {
        font-size: 20px;
    }

    .inner-button {
        width: 85%;
        height: auto;
        float: center;
        margin-bottom: 10px !important;
    }

    #apply-reimbursement {
        line-height: normal;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    #start {
        width: 100%;
    }

    .sub-text {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 0;
        font-size: 0.875rem;
    }

    #coming-soon {
        font-size: 0.875rem;
    }

    .greeting h1 {
        font-size: 36px;
        margin-top: 46px;
    }

    .button-title {
        font-size: 5.5vw;
    }

    /* .dashboard {
        margin-right: -1px;
    } */

    .dashboard-button {
        width: 93%;
        margin-top: 26px;
    }

    .dashboard .MuiButton-label {
        font-size: 4vw;
    }
}

/* iPhone 5/SE */
@media screen and (max-width: 320px) {
    .greeting h1 {
        font-size: 30px;
        margin-top: 25px;
    }

    .button-title {
        font-size: 20px;
    }

    .dashboard {
        background-size: cover;
    }

    .main-pane {
        width: 75%;
    }

    .dashboard-button {
        width: 100%;
        float: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }

    #app-stat {
        font-size: 5.5vw;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .inner-button {
        width: 85%;
        height: auto;
        float: center;
        margin-bottom: 10px !important;
    }

    #apply-reimbursement {
        line-height: normal;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    #start {
        width: 86%;
    }

    .sub-text {
        margin-bottom: 0;
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 1024px) {
    /* .main-pane {
    width: 75%;
    margin-left: 13vw;
  } */

    /* .dashboard-button {
        background-color: #A6DCEC;
    } */
    .inner-button {
        width: 85%;
        float: center;
        /* background-color: #A6DCEC; */
    }

    #start {
        width: 100%;
        height: auto;
        /* background-color: #A6DCEC; */
    }

    #start .MuiButton-label {
        font-size: 16px;
    }

    /* #reimbursement {
        background-color: #A6DCEC;
    } */

    #lamp {
        display: none;
    }

    #lamp-light {
        display: none;
    }
}

@media screen and (max-height: 795px) and (min-width: 343px) {
    .dashboard .MuiButton-label {
        font-size: 2.5vh;
    }

    .greeting h1 {
        margin-top: 10px;
        font-size: 5vh;
    }

    #confirm,
    #deny {
        height: 7vh;
    }

    #app-stat {
        height: 9vh;
        font-size: 3.5vh;
    }

    .sub-text {
        font-size: 2.5vh;
        padding: 1em 0;
    }

    #app-stat-title,
    .button-title {
        font-size: 3.5vh;
    }

    #reimbursement {
        height: 30%;
    }

    #apply-title {
        font-size: 3.5vh;
    }

    #coming-soon {
        font-size: 2.5vh;
    }

    .dashboard-button {
        width: 100%;
        height: 40%;
        padding-bottom: 10px;
        background-color: var(--red1);
    }
}