#description {
    margin-bottom: 40px;
}

@media screen and (min-width: 500px) {
    #description {
        font-size: 24px;
        width: 600px;
    }

    #comments {
        width: 395px !important;
    }
}

@media screen and (max-width: 499px) {
    #description {
        font-size: 18px;
        width: 80vw;
    }

    #comments {
        width: 266px !important;
    }
}

.optional-info-1 {
    margin-bottom: 10px;
}

.optional-info-2 {
    margin-top: 2%;
}

#website {
    width: 214px !important;
    color: white;
}

#website::placeholder, #github::placeholder, #linkedin::placeholder {
    width: 214px !important;
    color: white;
    opacity: 1;
}

.Website {
    margin-bottom: 15px;
}

#github {
    width: 214px !important;
}

.Github {
    margin-bottom: 15px;
}

#linkedin {
    width: 214px !important;
}

.Linkedin {
    margin-bottom: 30px;
}

#error-message {
    color: #ffa0a0;
}

.relative {
    position: relative;
}

.lightbulb-img {
    position: absolute;
    width: 120%;
    height: auto;
    bottom: -8rem;
    left: 150px;
}

@media screen and (max-width: 900px) {
    .lightbulb-img {
        visibility: hidden;
        display: none;
    }
}