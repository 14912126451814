.sponsor-home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 4rem 3rem 4rem 3rem;
}

.banner-img-sect {
    align-self: center;
}

.sponsor-home-blurb {
    align-self: center;
}

#banner-img {
    max-height: 100%;
    max-width: 100%;
}

.sponsor-home-blurb h1 {
    font-family: Recoleta;
    font-style: normal;
    font-weight: bold;
    color: black;
}

.sponsor-home-blurb {
    max-width: 80%;
    padding-left: 3rem;
    font-weight: 100;
}

.sponsor-home-blurb p{
	margin-top: 1rem;
}