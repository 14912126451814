.hack {
    align-content: center;
    /* margin-top: 5rem; */
    padding-bottom: 60px;
    padding-top: 8rem;
    display: flex;
    flex-wrap: wrap;
    /* max-width: 1400px; */
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    justify-content: flex-end;
    color: black !important;
    /* background-color: #E26052 */
    background-color: var(--red2);
    /* background-color: #FF6853; */
}

.bold {
    font-weight: normal;
}

@media screen and (max-width: 700px) {
    .hack{
        padding-top: 1rem;
    }

    .watering-can {
        width: 80% !important;
    }
    
    .side-ferns {
        position: absolute;
        right: -5.5rem !important;
        width: 100% !important;
        top: 8rem !important;
        margin: auto;
    
    }
}

.title-hack {
    text-align: left;
    font-family: Eugusto;
    margin-bottom: 3rem;
}

.center-horiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.watering-can {
    width: 40%;
}

.relative {
    position: relative;
}

.side-leaf {
    position: absolute;
    left: -280px;
    top: 10rem;
    width: 55%;

}

.side-ferns {
    position: absolute;
    right: -130px;
    top: -8rem;
    width: 43%;

}

.body-text-hack {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
    text-align: left;
    font-family: Inter;
    font-weight: 300;
    font-size: 1rem;
}

/* @font-face {
    font-family: Eugusto;
    src: url("../../../../assets/fonts/Eugusto\ Free\ Font.otf");
} */

.intro-blurb-header {
    font-weight: normal;
    color: black !important;
    font-family: Eugusto !important;
    padding: 10px 0px;
}

@media screen and (max-width: 1291px) {
    .intro-blurb {
        max-width: 45rem;
        position: block;
        top: initial;
        margin: 0 auto;
    }

    .intro {
        padding-top: 2rem;
    }

    .intro .container {
        margin: 0 3%;
    }
}