.footer {
    background-color: var(--blue1);
    color: #353535;
    text-align: left;
    padding: 2rem 10% 1rem 10%;
    z-index: 4;
}

.theme {
    font-size: 2.5rem;
    font-family: Eugusto;
    font-style: normal;
    font-weight: bold;
}

.current {
    margin: 0 0 1rem 0;
}

.socials, .previous, .links, .misc {
    font-size: 0.8rem;
    margin: 0 0.5rem 2rem 0.5rem;
}

.col-header {
    font-weight: bold;
}

.misc .footerlink {
    font-size: 0.65rem;
}

.message {
    font-size: 0.8rem;
    font-weight: 100;
}

.footerlink {
    /* color: white !important; */
    color: #353535;
    font-weight: 100;
    text-decoration: none;
    transition: .2s;
}

.footer p {
    margin-bottom: 0;
}

.footerlink:hover {
    color: #FF844C;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .footer .theme{
        font-size: 2.5rem;
    }
}