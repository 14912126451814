.items {
    margin: 5% 10% 15% 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.item-link:hover {
    text-decoration: none;
}

.item:hover {
    transition: 0.2s;
    transform: scale(1.1);
    color: rgb(0, 146, 141);
    cursor: pointer;
    text-decoration: none;
}

.item-img {
    width: 50px;
}

.links-subheading {
    margin-top: 15px;
    font-size: 14px;
    color: white;
}

@media screen and (min-width: 320px) and (max-width: 414px) {
    .items {
        margin-top: -210px !important;
    }
}

@media screen and (max-width: 670px) {
    .items {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-evenly;
        margin-top: 0;
    }

    .item {
        width: 125px;
    }
}