.Race {
    display: flex;
}

.Gender {
    margin-bottom: 2%;
}

.Website {
    margin-bottom: 1%;
}

.Github {
    margin-bottom: 1%;
}

.MuiMenu-paper {
    display: none;
}

.MuiButton-label {
    align-items: flex-start;
    text-align: left;
}

@media screen and (min-width: 500px) {
    #description-more-info {
        font-size: 24px;
        width: 600px;
    }
}

@media screen and (max-width: 499px) {
    #description-more-info {
        font-size: 18px;
        width: 80vw;
    }
}

.raceDiv {
    margin-bottom: 60px;
}

.genderDiv {
    margin-bottom: 80px;
}

.howFindOutDiv {
    margin-bottom: 80px;
}

.more-info-firsthack {
    margin-bottom: 35px;
}

.form-popup {
    overflow-y: scroll;
    position: absolute;
    z-index: 100;
    width: inherit;
    font-weight: 100;
}

.form-popup::-webkit-scrollbar {
    -webkit-appearance: none;
}

.form-popup::-webkit-scrollbar:vertical {
    width: 11px;
}

.form-popup::-webkit-scrollbar:horizontal {
    height: 11px;
}

.form-popup::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, .35);
    /* should match background, can't be transparent */
    background-color: rgba(255, 255, 255, .5);
}

@media screen and (min-width: 500px) {
    .raceDiv {
        width: 408px !important;
    }

    .genderDiv {
        width: 408px !important;
    }

    .howFindOutDiv {
        width: 408px !important;
    }

    .form-popup {
        background-color: #576A2A !important;
        padding-top: 15px !important;
        padding-left: 20px !important;
        padding-bottom: 15px !important;
        max-height: 250px !important;
        overflow: scroll !important;
        overflow-y: scroll !important;
        color: white !important;
        font-size: 18px !important;
    }
}

@media screen and (max-width: 320px) {
    .raceDiv {
        width: 280px !important;
    }

    .genderDiv {
        width: 280px !important;
    }

    .ducks-video {
        visibility: hidden;
        display: none;
    }

    .howFindOutDiv {
        width: 280px !important;
    }

    .form-popup {
        background-color: #576A2A !important;
        padding-top: 15px !important;
        padding-left: 20px !important;
        padding-bottom: 15px !important;
        max-height: 200px !important;
        overflow: scroll !important;
        overflow-y: scroll !important;
        color: white !important;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 499px) and (min-width: 330px) {
    .raceDiv {
        width: 350px !important;
    }

    .genderDiv {
        width: 350px !important;
    }

    .ducks-video {
        visibility: hidden;
        display: none;
    }

    .howFindOutDiv {
        width: 350px !important;
    }

    .form-popup {
        background-color: #576A2A !important;
        padding-top: 15px !important;
        padding-left: 20px !important;
        padding-bottom: 15px !important;
        max-height: 200px !important;
        overflow: scroll !important;
        overflow-y: scroll !important;
        color: white !important;
        font-size: 14px !important;
    }
}

.dropArrow {
    margin-left: 50px;
}

#required {
    margin-bottom: 50px;
}

.relative {
    position: relative;
}

.ducks-video {
    position: absolute;
    width: 55%;
    bottom: 3.5rem;
    left: 440px;
}

@media screen and (max-width: 900px) {
    .ducks-video {
        visibility: hidden;
        display: none;
    }

    .relative {
        display: none;
    }
}