.toolbar {
    /* position: fixed; */
    z-index: 8;
    top: 0;
    width: 100%;
}

.white-logo {
    filter: invert(1);
}

/* .toolbar-about {
    padding-top: 0.2rem;
    font-size: 1.2rem;
} */

.main-toolbar .toolbar {
    padding-top: 2rem;
    /* background-color: var(--green2); */
}

.dashboard-toolbar .toolbar {
    padding-top: 2rem;
    background-color: var(--red1)
}

.toolbar a:hover {
    text-decoration: none;
}

.navBarExtended {
    padding: 1000px;
}

#logo-img {
    display: flex;
    align-content: flex-start;
    /* max-width: 50px; */
    max-height: 50px;
    width: auto;
    height: auto;
    transition: 0.2s;
}

#logo-img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    #logo-img {
        max-height: 40px;
    }
}

#mlh-img {
    width: 7%;
    z-index: 9;
    position: absolute;
    right: 2rem;
    top: 0;
}

.plant {
    position: absolute;
    z-index: 9;
    right: 42vw;
    top: -15rem;
    pointer-events: none;
}

#hanging-plant {
    width: 120%;
    position: relative;
    pointer-events: none;
    z-index: -100;
}

.main-toolbar .tool-bar-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: black !important;
}

.main-toolbar .tool-bar-container .col {
    padding: 0 20px;
}

@font-face {
    font-family: Recoleta;
    src: url("../../assets/fonts/Recoleta-Black.ttf");
}

.toolbar-virtual-space,
.toolbar-about,
.toolbar-itinerary,
.toolbar-sponsors,
.toolbar-faq {
    color: black;
    z-index: 10;
}

.regist-toolbar {
    /* margin-top: 1rem; */
    background-color: var(--green1);
}

.dashboard-toolbar .nav-link,
.main-toolbar .nav-link {
    color: black !important;
    font-size: 1.2rem !important;
}

.regist-toolbar .nav-link {
    color: white !important;
    padding-top: 2rem;
}

.toolbar-virtual-space:hover,
.toolbar-about:hover,
.toolbar-itinerary:hover,
.toolbar-sponsors:hover,
.toolbar-faq:hover,
.nav-link:hover {
    cursor: pointer !important;
    border: none !important;
    transition: 0.2s;
    color: #ED695B !important;
}

.toolbar-items {
    display: flex;
    flex-direction: row;
}

.sign-in-col {
    margin: auto;
}

.toolbar-signin,
.sign-in-col .stickynote-button {
    color: black;
    padding: 0 1rem 0 1rem;
    background-color: transparent;
    border-radius: 16px;
    border: 2px solid #444444;
    font-size: 1.2rem;
    position: static;
    margin: 0;
}

.toolbar-signin:hover,
.sign-in-col .stickynote-button:hover {
    cursor: pointer !important;
    transition: 0.2s;
    color: white;
    background-color: #ED695B;
    border: 2px solid #ED695B;
}

.regist-toolbar .tool-bar-container a {
    color: white;
    font-size: 1.3rem;
    /* padding-top: 2rem; */
}

.regist-toolbar .tool-bar-container .sign-in-col button {
    color: white;
    border: 2px solid white;
}

.regist-toolbar .tool-bar-container .sign-in-col button:hover {
    transition: 0.2s;
    color: #ED695B;
    border: 2px solid #ED695B;
}

@media screen and (max-width: 1100px) {
    #mlh-img {
        display: none !important;
    }

    .plant {
        position: absolute;
        z-index: 9;
        right: 50vw;
        top: -5vh;
        pointer-events: none;
    }

    #hanging-plant {
        position: relative;
    }

    .main-toolbar .tool-bar-container .col {
        /* width: 0; */
        align-self: flex-start;
        display: flex;
        align-items: center;
        padding-left: 1px;
        font-size: 2rem;
        height: 20%;
        /* font-family: Recoleta;
        font-weight: normal; */
    }

}

@media screen and (max-width: 800px) {
    #mlh-img {
        display: none !important;
    }

    #hanging-plant {
        display: none !important;
    }

    .toolbar nav {
        padding-left: 0vw !important;
        padding-right: 0vw !important;
    }

    .regist-toolbar {
        display: none;
    }

    .toolbar nav .navbar-brand {
        margin-left: 30px !important;
    }

    .toolbar nav .navbar-toggler {
        margin-right: 4vw !important;
        margin-bottom: 2vw !important;
        margin-top: 1vw !important;
        display: none;
    }

    .toolbar .col{
        justify-content: center;
    }

    .white-logo{
        filter: invert(1);
    }

    .main-toolbar .tool-bar-container {
        height: 90vh;
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .toolbar-signin {
        width: 10rem;
        font-size: 24px !important;
        margin: auto;
    }

    .main-toolbar .tool-bar-container .col {
        /* width: 0; */
        align-self: flex-start;
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-size: 2rem;
        height: 20%;
        font-family: Recoleta;
        font-weight: normal;
    }

    .main-toolbar .tool-bar-container .col:nth-of-type(1) {
        background-color: var(--green1)
    }

    /* .main-toolbar .tool-bar-container .col:nth-of-type(2) {
        background-color: var(--red1);
    }  */

    /* .main-toolbar .tool-bar-container .col:nth-of-type(3) {
        background-color: #AEEBFC;
    }

    .main-toolbar .tool-bar-container .col:nth-of-type(4) {
        background-color: rgba(70, 181, 184, 1);
    }

    .main-toolbar .tool-bar-container .col:nth-of-type(5) {
        background-color: rgba(253, 247, 220, 1);
    }

    .main-toolbar .tool-bar-container .sign-in-col {
        display: none !important;
    }
    */

    .nav-link {
        color: #444444 !important;
    }

    .nav-link:hover {
        cursor: pointer !important;
        border: none !important;
        transition: 0.2s;
        color: #444444 !important;
    }

    .dashboard-toolbar .tool-bar-container,
    .regist-toolbar .tool-bar-container {
        height: 100vh;
        padding-top: 50%;
        padding-bottom: 50%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.5rem;
    }

    .dashboard-toolbar .toolbar {
        background-color: var(--red1);
    }

    .regist-toolbar .tool-bar-container a {
        color: white !important;
    }

    .regist-toolbar .tool-bar-container .sign-in-col button {
        color: white;
        border: 2px solid white;
    }
}