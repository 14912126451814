.basic-info-1 {
    display: 'flex';
    flexWrap: 'wrap';
    /* margin-bottom: 15px; */
}

.basic-info-1 label {
    color: white !important;
}

.basic-info-5 {
    display: flex;
    flexWrap: 'wrap';
    margin-bottom: 15px;
}

.basic-info-5 label {
    color: white !important;
}

.basic-info-2 {
    display: 'flex';
    flexWrap: 'wrap';
    margin-bottom: 15px;
}

.basic-info-2 label {
    color: white !important;
}

.basic-info-3 {
    margin-top: 30px;
    margin-bottom: 40px;
}

.basic-info-3 label {
    margin-bottom: 5px;
    font-family: Inter;
    color: white !important;
}

.basic-info-4 {
    margin-top: 30px;
    margin-bottom: 0px;
}

.basic-info-4 label {
    color: white !important;
}

#subtext {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.75) !important;
}

.basic-info-5 {
    margin-bottom: 0px;
    font-weight: 100 !important;
}

.basic-info-5 a {
    color: white !important;
    text-decoration: underline;
    font-weight: 100 !important;
}

.basic-info-6 a {
    color: white !important;
    text-decoration: underline;
}

.MuiTypography-body1 {
    font-weight: 100 !important;
    /* background: var(--green1) !important; */
}

#phoneNumber {
    color: white !important;
}

.relative {
    position: relative;
}

.radishes-img {
    position: absolute;
    width: 70%;
    height: auto;
    bottom: -8rem;
    left: 300px;
    z-index: -1;
}


@media screen and (max-width: 900px) {
    .radishes-img {
        visibility: hidden;
        display: none;
    }
}



/* .MuiInputBase-input{
  background: var(--green1) !important;
} */

/* .mlhPrivacy MuiFormControl-root mlhPrivacy{
	margin-top: 120px;
}

.mlhPrivacy MuiFormControlLabel-root {
	margin-top: -120px;
}

.mlhPrivacy MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 {
	margin-top: 120px;
} */