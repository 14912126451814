.sponsors {
    align-content: center;
    text-align: left;
    color: #444444;
    background-color: var(--green2);
}

.sponsors-section {
    margin: 1rem 2rem 1rem 0;
}

.sponsors-heading {
    margin: 0rem 12% 0rem 12%;
    padding: 6rem 0 0 0;
}

.sponsors-heading h1 {
    font-family: Eugusto;
    font-style: normal;
    font-weight: normal;
    color: black;
}

.partnership {
    margin: 1rem 0 0 0;
}

.sponsors-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    margin: 0rem 12% 0rem 12%;
    padding: 1rem 0 3rem 0;
}

.sponsors-text a {
    color: #444444;
    text-decoration: none;
}

.sponsors-text a:hover {
    color: #444444;
    text-decoration: none;
}

.sponsors-items {
    margin: 0rem 12% 0rem 12%;
    /* padding: 0 0 4rem 0; */
    /* display: flex; */
    flex-wrap: wrap;
}


.sponsors-name {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    margin: 2rem 0 1rem 0;
}

/* .sponsors-peta {
    margin-top: -2rem;
    max-width: 30rem;
}

.sponsors-img-peta {
    transition: .2s ease-in-out;
    max-width: 14rem;
    margin: 0.5rem;
}

.sponsors-img-peta:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-tera {
    max-width: 30rem;
}

.sponsors-img-tera {
    transition: .2s ease-in-out;
    max-width: 12rem;
    margin: 0.5rem;
}

.sponsors-img-tera:hover {
    cursor: pointer;
    transform: scale(1.1);
} */

.sponsors-giga {
    width: 100%;
}

.sponsors-img-giga {
    transition: 0.2s ease-in-out;
    /* max-width: 30vw; */
    max-height: 12vw;
    margin: 1rem;
    object-fit: cover;
}

.sponsors-img-giga:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-mega {
    max-width: 100%;
}

.sponsors-img-mega {
    transition: 0.2s ease-in-out;
    /* max-width: 25vw; */
    max-height: 10vw;
    margin: 0.5rem;
    object-fit: cover;
}

.sponsors-img-mega:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-kilo {
    max-width: 100%;
}

@media (min-width: 1990px) {
    .sponsors-mega {
        max-width: initial;
    }

    .sponsors-mega .sponsors-img {
        display: flex;
        flex-direction: row;
    }

    /* .sponsors-kilo {
        margin-left: 1em;
    } */
}

.sponsors-img-kilo {
    transition: 0.2s ease-in-out;
    /* max-width: 15vw; */
    max-height: 8vw;
    margin: 1rem 2rem 1rem 0;
    object-fit: cover;
}

.sponsors-img-kilo:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-micro {
    transition: 0.2s ease-in-out;
    width: 100%;
    /* max-height: 30px; */
    margin: 1rem 2rem 1rem 0;
    object-fit: cover;
}

.sponsors-img-micro {
    transition: 0.2s ease-in-out;
    /* max-width: 10vw; */
    max-height: 6vw;
    margin: 1rem 2rem 1rem 0;
    object-fit: cover;
}

.sponsors-img-micro:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.placeholderImg {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .sponsors-heading h1 {
        margin-top: 3.5rem;
        font-size: 1.8rem;
        font-weight: normal;
    }

    .sponsors-heading {
        margin: 0;
        padding: 1rem 2rem;
    }

    .sponsors-name {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }

    .sponsors-section {
        margin: 0 auto;
    }

    .sponsors-text {
        font-size: 1rem;
        padding: 2rem 2rem;
        margin: 0;
    }

    .sponsors-items {
        margin: 0rem 2rem;
        /* padding: 0 0 4rem 0; */
        /* display: flex; */
        flex-wrap: wrap;
    }

    .sponsors-giga,
    .sponsors-mega,
    .sponsors-kilo,
    .sponsors-micro
    .sponsors-partners {
        margin-left: 0;
        max-width: 100%;
    }

    .sponsors-img-micro {
        padding: 0 !important;
    }
}

.relative {
    position: relative;
}

.sideferns {
    position: absolute;
    right: -150px;
    bottom: 0.1rem;
    width: 43%;
}

.left-flower {
    position: absolute;
    width: 35%;
    left: -220px;
}