.confirm-info {
  overflow-y: scroll;
  scrollbar-width: none;
  border: 2px solid #444444;
  border-radius: 15px;
  color: #444444;
  text-align: left;
  padding: 20px;
  background: #A6DCEC;
  max-height: 65vh !important;
  margin-top: 5%;
}

.confirm-info a {
  color: #444444;
  font-weight: bold;
}

.confirm-info p {
  font-weight: 100;
  margin: 5% 0;
}

.MuiTypography-body1 {
  font-family: Inter !important;
}

.confirm-info h2 {
  text-align: center;
  font-weight: bold;
}

.confirm-info label {
  font-family: Inter !important;
  font-size: 100%;
  font-weight: 100;
}

.legal-name {
  display: 'flex';
  flex-wrap: 'wrap';
}

.confirm {
  margin-bottom: 10px;
}

.confirm-select {
  margin-bottom: 20px;
}

.confirm-text{
  margin-bottom: 20px;
}

.confirm-text span {
  font-size: 80%;
}

/* #phoneNumber {
  color: #444444 !important;
} */

.confirm-info .submit {
  width: 100% !important;
}
