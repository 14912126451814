html, body {
    overflow-x: hidden;
}

.main {
    width: 100%;
    height: 100vh;
    margin-bottom: 0px;
    padding-bottom: 100px;
    background-color: var(--green2);
    /* background: url("web_animation.mp4"); */
    /* justify-content: center;
    text-align: center;
    display: flex; */

}

@font-face {
    font-family: Eugusto;
    src: url("../../../../assets/fonts/Eugusto\ Free\ Font.otf");
}

@font-face {
    font-family: Inter;
    src: url("../../../../assets/fonts/Inter-Bold-slnt=0.ttf");
}

.top-text {
    /* margin: 1rem 10% 2rem; */
    padding-top: 0rem;
    text-align: center;
    font-size: 2.2vw;
    font-family: Inter;
    font-weight: bolder;
}

.videoTag {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 65px;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}


.big-text {
    font-family: Eugusto;
    /* margin: 0.5rem 10% 0.5rem 10%; */
    font-size: 7.2vw;
    text-align: center;
    /* font-weight: bold; */
    /* text-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px; */
}

.bottom-text {
    text-align: center;
    font-size: 2vw;
    font-family: Inter;
    font-weight: bolder;
}

.text {
    display: flex;
    top: 30vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.main-button {
    align-items: center;
    margin: 2rem 10% 1rem;
}

.static-scene {
    display: None;
}

.resize {
    height: auto;
    width: 113%;
    position: relative;
}

.resize-large {
    height: auto;
    width: 130%;
}

.images {
    top: -20vw;
    /* bottom: 70%; */
    position: relative;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-leaf {
    position: relative;
    /* bottom:22rem; */
    left: -20vw;
    pointer-events: none;
    /* top: 0;
    left: 0;*/
    /* margin-bottom: 200rem;
    margin-left: -105rem; */
}

.right-leaf {
    position: relative;
    /* bottom: 120rem; */
    left: 10vw;
    bottom: 15vh;
    pointer-events: none;
}

#hanging-plant {
    pointer-events: none;
    z-index: -100;
    width: 100%;
    position: relative;
}


@media screen and (max-width: 700px) {
    .main {
        height: 50vh;
        background-color: var(--green2);
        z-index: 0;
        position: relative;
        top: -2rem;
    }

    #hanging-plant {
        display: none !important;
    }

    .main-container {
        background-color: var(--green2);
    }

    .left-leaf {
        display: none;
    }

    .right-leaf {
        left: 15vw;
        bottom: -22vh;
        width: 90%;
        height: auto;
        z-index: 1;
        position: relative;
    }

    .resize {
        width: 140vw;
        height: auto;
    }

    .videoTag {
        display: none;
    }

    .animated-scene {
        display: None;
    }

    .top-text {
        /* margin: 1rem 10% 2rem; */
        /* padding-top: 5rem; */
        margin: 0.1em;
        font-size: 5vw;
        width: 100%;
    }

    .big-text {
        margin: 0.1em;
        font-family: Eugusto;
        font-size: 13vw;
        font-weight: 500;
        text-shadow: none;
    }

    .bottom-text {
        /* margin: 1rem 10% 1.75rem; */
        margin: .1em;
        text-align: left;
        font-size: 5vw;
    }

    .main-button {
        margin: 2vh;
        z-index: 100;
    }

    .images {
        top: -35vw;
        /* bottom: 70%; */
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        pointer-events: none;
    }

    .text {
        padding: 0;
        top: 10vh;
        z-index: 2;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
    }
}

@keyframes onemove {
    0% {
        margin-left: -720px;
        margin-top: -280px;
    }

    45% {
        margin-left: -2000px;
        margin-top: 50px;
    }

    100% {
        margin-left: -2200px;
        margin-top: 70px;
    }
}

@keyframes twomove {
    0% {
        margin-left: 220px;
        margin-top: -720px
    }

    60% {
        margin-left: -1100px;
        margin-top: -280px;
    }

    100% {
        margin-left: -1200px;
        margin-top: -280px;
    }
}

@keyframes threemove {
    0% {
        margin-left: 450px;
        margin-top: -600px
    }

    100% {
        margin-left: -1300px;
        margin-top: -280px;
    }
}

@keyframes oneupmove {
    0% {
        margin-left: 650px;
        margin-top: -490px;
    }

    100% {
        margin-left: -20px;
        margin-top: -795px;
    }
}

@keyframes twoupmove {
    0% {
        margin-left: 740px;
        margin-top: -490px;
    }

    100% {
        margin-left: 225px;
        margin-top: -815px;
    }
}

@keyframes threeupmove {
    0% {
        margin-left: 700px;
        margin-top: -520px;
    }

    100% {
        margin-left: 440px;
        margin-top: -695px;
    }
}