.registration {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 80px;
    height: auto;
    min-height: 100%;
    padding-left: 10vw;
    text-align: left;
    display: block;
    overflow: auto;
    color: white;
    background-size: cover;
    background-position-y: bottom;
    background-repeat: no-repeat;
    font-weight: 100;
    background-color: var(--green1);
    width: 100%;
    overflow-x: hidden;
}

.form-name {
    padding-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
}


.form-name h1 {
    font-family: Recoleta;
    font-weight: 700;
    color: white;
}


.registration input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--green1) inset !important;
    -webkit-text-fill-color: white !important;
}

.stage {
    padding-top: 24px;
    margin-bottom: 0rem;
    font-size: 18px;
    padding-left: 20px;
}



@media screen and (max-width: 900px) {
    .registration {
        padding: 2rem 1.2rem 0;
    }
}

@media screen and (min-width: 500px) {
    .form-name h1 {
        font-size: 52px;
    }
}

@media screen and (max-width: 499px) {
    .form-name h1 {
        font-size: 36px;
    }
}

.form-content {
    font-family: Inter;
    margin-bottom: 30px;
}

.form-content-mobile {
    font-family: Inter;
    min-height: 65vh;
    margin-bottom: 30px;
}

.form-progress {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.form-last-button {
    margin-bottom: 50px;
}

.buttons {
    margin-right: 30px;
}

.next {
    width: 112px !important;
    margin-right: 20px !important;
}

.back {
    width: 112px !important;
    margin-right: 20px !important;
}

.submit {
    width: 253px !important;
}

button {
    outline: none !important;
}

.background {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--green1);
}