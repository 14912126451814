.dayof-itinerary {
    /* margin: 0 0 1rem 10%; */
    color: white;
    text-align: left;
    background-color: #415364;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.dayof-itinerary .row {
    margin-left: auto;
    margin-right: auto;
}

.dayof-itinerary table {
    margin-right: 10%;
}

.dayof-itinerary .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.dayof-itinerary th:nth-child(1) {
    font-family: Covik Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 52px;
    /* identical to box height */

    color: #ffffff;
    padding-bottom: 35px;
}

.dayof-itinerary td:nth-child(1) {
    font-family: Akkurat Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    color: #ffffff;
    padding-bottom: 35px;
}

.caption {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
}

#saturday {
    max-width: 300px;
}
#prize-title {
    padding-bottom: 20px;
}

#prize-categories {
    font-weight: normal;
}

.slide {
    background: none !important;
}

.container-map {
    position: relative;
    text-align: center;
    color: white;
}

.labelMap {
    font-family: Covik Sans;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    left: 50%;
    bottom: 90%;
    transform: translate(-50%);
}

@media screen and (max-width: 575px) {
    .dayof-itinerary .col-sm {
        margin-left: 30%;
    }
    .dayof-itinerary .map-sect {
        margin-left: auto;
    }
    .dayof-itinerary .carousel .thumb {
        width: 55px;
    }
}

@media screen and (max-width: 414px) {
    .dayof-itinerary {
        flex-direction: column;
        padding-left: 16%;
    }

    .map-sect {
        margin-left: -20%;
        padding-left: 5%;
    }
}

@media screen and (min-width: 1200px) {
    .dayof-itinerary .container {
        max-width: 1200px !important;
    }
}

@media screen and (max-width: 414px) {
    .dayof-itinerary .col-sm {
        margin-left: 0%;
    }
    .dayof-itinerary .map-sect {
        margin-left: -11%;
    }
    .dayof-itinerary .carousel .thumbs {
        padding-left: 0;
    }
}

@media screen and (max-width: 1200px) {
    .carousel {
        width: 60% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    #saturday {
        margin-left: 20%;
    }
}

@media screen and (max-width: 769px) {
    .carousel {
        width: 80% !important;
    }
    .labelMap {
        /* top: -3px; */
        width: 100%;
    }
    #saturday {
        margin-left: 16%;
    }
}

@media screen and (max-width: 767px) {
    #saturday {
        margin-left: 0%;
    }
}

@media screen and (max-width: 320px) {
    .carousel {
        width: 105% !important;
    }
}
