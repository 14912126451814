.intro {
    align-content: center;
    /* margin-top: 5rem; */
    padding-bottom: 60px;
    padding-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    /* max-width: 1400px; */
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    justify-content: flex-end;
    color: black !important;
    /* background-color: #E26052 */
    background-color: var(--red1);
    /* background-color: #FF6853; */
}

.bold {
    font-weight: normal;
}

@media screen and (max-width: 700px) {
    .intro-blurb-header {
        margin-top: 5rem;
    }
}

#juiceImg {
    max-width: 70%;
    min-width: 15rem;
    margin-bottom: 2rem;
}

.intro-blurb {
    margin: 0 10% 0;
    /* max-width: 20rem; */
    /* min-width: 35vw; */
    text-align: left;
    font-family: Inter;
    /* position: relative; */
    /* top: 250px; */

}

.intro-blurb-text {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
    font-weight: 300;
    font-size: 1rem;
}

@font-face {
    font-family: Eugusto;
    src: url("../../../../assets/fonts/Eugusto\ Free\ Font.otf");
}

.intro-blurb-header {
    font-weight: normal;
    color: black !important;
    font-family: Eugusto !important;
    padding: 10px 0px;
}

/* fix for "Welcome to After School" */
@media screen and (max-width: 1291px) {
    .intro-blurb {
        max-width: 45rem;
        position: block;
        top: initial;
        margin: 0 auto;
    }

    .intro {
        padding-top: 2rem;
    }

    .intro .container {
        margin: 0 3%;
    }
}

@media screen and (max-width: 534px) {
    .intro-blurb-header {
        font-size: 2rem;
        padding: 10px;
    }
}

@media screen and (max-width: 426px) {
    .intro-blurb-header {
        font-size: 1.8rem;
    }
}


@media screen and (max-width: 383px) {
    .intro-blurb-header {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 342px) {
    .intro-blurb-header {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 320px) {
    .intro-blurb-header {
        font-size: 1.4rem;
    }
}