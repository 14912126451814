.centering-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hoverable {
    cursor: pointer;
}

.hoverable:hover {
    background-color: #f5f5f5;
}

.school-width {
    min-width: 15rem;
}

.overflow {
    overflow-y: scroll;
    max-height: 150vh;
}

.actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.password-input {
    max-width: 300px;
    margin: 0 auto;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: #e0e0e0;
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: large;
}

.search-bar {
    width: 70vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.search-input {
    max-width: 300px;
}

.med-width {
    min-width: 10rem;
}