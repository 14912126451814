#tacks {
  max-height: 130%;
  max-width: 130%;
}

#h3-2 {
  margin-bottom: 20px;
  font-weight: 700;
  font-family: Recoleta;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
}

h3 {
  margin-bottom: 20px;
}

#heading {
  font-weight: 700;
  color: #46B5B8;
}

#headingL {
  font-weight: 500;
}

#text {
  margin-bottom:15px !important;
  color: #444444;
  font-weight: 100;
}

#link, #link1, #link2, #link3, #link4 {
  font-weight: 700;
  color: #57C7B9 !important;
}

.why-sponsor p {
  margin-bottom: 0px !important;
}
.why-sponsor-left{
  margin-right: 120px;
  font-weight: 100;
}

.why-sponsor-right {
  margin-top:100px;
  font-weight: 100;
}

.past-projects {
  margin-top: 100px;
  margin-bottom:100px;
  margin-right: 120px;

}

.tack-graphics {
  padding-top: 5rem;
}

.why-sponsor {
  background: rgba(166, 220, 236, 0.3);
  padding: 4rem 3rem 4rem 3rem;
}

#sponsor-email{
  color: #46B5B8;
}

.why-sponsor ul {
  list-style-position: inside;
  padding: 0;
}