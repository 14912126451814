.error-message {
  font-family: Inter;
  color: red;
  font-weight: bold;
  font-size: 13px;
}

.info-message {
  font-family: Inter;
  font-size: 13px;
  color: black;
}

.login-join-modal {
  position: fixed;
  /* font-family: Arial, Helvetica, sans-serif; */
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  transition: opacity 1s ease-in 0s;
  pointer-events: auto;
  overflow-y: auto;
}

.login-join-body {
  width: 430px;
  position: relative;
  margin: 10% auto;

  background: #ffffff;
  border-radius: 12px;
}

#other-tab {
  background: #e8e8e8;
  font-family: Eugusto;
  font-style: normal;
  font-weight: 400;
  color: #444444;
}

#active-tab {
  background: #ffffff;
  font-family: Eugusto;
  font-style: normal;
  font-weight: 500;
  color: #444444;
}

.join-tab {
  width: 50%;
  height: 80px;

  border: none;
  border-radius: 12px 0px;

  font-family: Eugusto;
  font-style: normal;
  font-weight: 100;
  font-size: 24px;

  /* Disable joining */
  /* color: #a9a9a9; */
}

/* Disable joining */
/* .join-tab:hover {
    cursor: default;
} */

.login-tab {
  width: 50%;
  height: 80px;

  border: none;
  border-radius: 0px 12px;

  font-family: Eugusto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
}

form.loginJoin-form {
  margin-top: 30px;
  text-align: left;
  padding-left: 47px;
  padding-right: 47px;
  font-family: Inter;
  font-weight: 100;
  padding-bottom: 32px;
}

button:focus {
  outline: 0;
}

label {
  line-height: 19px;
  font-size: 16px;
}

.form-control {
  width: 336.5px;
  height: 33px;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 2px;
}

.button-row {
  margin-top: 15px;
  text-align: right;
  overflow: hidden;
}

.button-row button {
  float: right;
  /* reverses the button order */
}

.login-join-button {
  width: 72.39px;
  height: 33px;

  border: 1px solid var(--red2);
  box-sizing: border-box;
  border-radius: 50px;

  background: var(--red2);

  font-size: 12px;
  font-weight: 700;

  color: #ffffff;

  margin-left: 18px;
}

/* add darker hover effect */
.login-join-button:hover {
  background: #e98378;
}

.cancel-button {
  width: 72.39px;
  height: 33px;

  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 50px;

  background: #ffffff;

  font-size: 12px;
  font-weight: 700;

  color: #2d3d53;
}

/* add darker hover effect for cancel button */
.cancel-button:hover {
  background: #dddddd;
}

#forgot-pw {
  font-size: 13px;
  cursor: pointer;
}

#forgot-pw:hover {
  text-decoration: underline;
}

#submit-button {
  width: 72.39px;

  border: 1px solid #ed695b;
  box-sizing: border-box;
  border-radius: 50px;

  background: #ed695b;

  font-size: 12px;
  line-height: 33px;
  font-weight: 700;

  color: #ffffff;

  margin-left: 18px;
}

.stickynote-button:hover {
  cursor: pointer;
  background: #e77164;
  border: 2px solid #e77164;
}

.stickynote-button {
  /* transform: rotate(-15deg); */
  transition: 0.3s;
  font-size: 1.8vw;
  /* position: absolute; */
  /* margin: 0rem 10% 1rem 10%; */
  /* top: 25%;
  right: 22.5%; */
  border-radius: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Inter;
  font-weight: 100;
  color: #fff;
  background: var(--red2);
  border: 2px solid var(--red2);
  display: flex;
  z-index: 10000;
}

@media screen and (max-width: 550px) {
  .login-join-body {
    /* width: inherit; */
    width: 90%;
  }

  .form-control {
    width: 100%;
  }
}

/* fix sticky note overlap */
@media screen and (max-width: 750px) {
  .stickynote-button {
    font-size: 1rem;
    /* top: 30%;
      right: 29.5%; */
  }
}

@media screen and (max-height: 600px) {
  .stickynote-login {
    font-size: 4vh;
    right: 22.5%;
    padding: 2px;
    border-radius: 10px;
  }

  .stickynote-dashboard {
    font-size: 4vh;
    right: 25%;
  }
}

.transparent {
  color: white;
}

.transparent:hover {
  text-decoration: none;
  color: white;
}
