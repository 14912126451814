.itinerary {
    /* margin: 4rem 10% 1rem 20%; */
    padding: 9rem 0 5rem 0;
    min-height: 100%;
    color: white;
    text-align: left;
    /* background-color: #46B5B8; */
    background-color: var(--red3);
}

.itinerary h2 {
    text-align: left;
    padding-bottom: 0.7rem;
    font-weight: bold;
    font-family: Inter;
    font-size: 1.5rem;

}

.itinerary-subtitle {
    font-family: 'Inter';
}

.itinerary p {
    font-family: 'Inter';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 0.75;
    /* padding-bottom: 3rem; */
}

.itinerary .col{
    padding-bottom: 1.5rem;
}


.img {
    width: 170%;
    margin: auto;
    height: auto;
    position: relative;
    right: 9rem;
}

.relative {
    position: relative;
}

.img-flower {
    position: absolute;
    width: 22%;
    height: auto;
    right: -80px;
}

@media screen and (max-width: 900px) {
    .img {
        width: 190%;
        height: auto;
        right: 4rem;
    }

    .itinerary h1 {
        font-size: 2rem;
        margin-bottom: -0.2rem;
        margin-top: 1.2rem;
    }

    .itinerary h2 {
        font-size: 1.4rem;
        margin-bottom: -0.5rem;

    }

    .img-flower {
        position: absolute;
        width: 40%;
        height: auto;
        right: -5rem;
        bottom: -125rem;
    }

    .itinerary {
        padding: 8% 8%;
    }

    .itinerary p{
        line-height: 1.2;
    }

    .itinerary .col{
        padding-bottom: 1rem;
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
    }

    .itinerary .row{
        width: 100%;
    }
}

@media screen and (max-width: 534px) {
    .itinerary-header {
        font-size: 2rem;
    }

    .itinerary p{
        line-height: 1.2;
    }

    .itinerary h1{
        padding-top: 1.5rem;
    }
}

@media screen and (max-width: 426px) {
    .itinerary-header {
        font-size: 1.8rem;
    }

    .itinerary p{
        font-size: 2rem;
        padding: 0;
    }

}


@media screen and (max-width: 383px) {
    .itinerary-header {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 342px) {
    .itinerary-header {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 320px) {
    .itinerary-header {
        font-size: 1.4rem;
    }
}