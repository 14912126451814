.meetteam {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    /* max-width: 1400px; */
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    justify-content: flex-end;
    color: black !important;
    /* background-color: #E26052 */
    background-color: var(--red1);
    /* background-color: #FF6853; */
}

.bold {
    font-weight: normal;
}

@media screen and (max-width: 700px) {
    .intro-blurb-header {
        margin-top: 3rem;
    }
    .body-hack {
        padding: 8%;
        margin-top: 3rem;
        margin-bottom: 4rem;
    }

    .title-hack {
        margin-bottom: 1.5rem !important;
        font-size: 1.8rem;
    }

    .meetteam {
       padding: 0;
       min-height: 0;
    }
}

.title-hack {
    text-align: left;
    font-family: Eugusto;
    margin-bottom: 3rem;
}

.center-horiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.relative {
    position: relative;
}

.side-leaf-right {
    transform: rotate(205deg);
    position: absolute;

    width: 420px;
    height: auto;
    top: 45vh;
    right: -140px;
    z-index: 1;
}

/* @font-face {
    font-family: Eugusto;
    src: url("../../../../assets/fonts/Eugusto\ Free\ Font.otf");
} */

.intro-blurb-header {
    font-weight: normal;
    color: black !important;
    font-family: Eugusto !important;
    padding: 10px 0px;
}

.team-img {
    max-width: 100%;
    min-width: 18rem;
}

@media screen and (max-width: 1100px) {
    .side-leaf-right {
        visibility: hidden;
    }
}

@media screen and (max-width: 1291px) {
    .intro-blurb {
        max-width: 45rem;
        position: block;
        top: initial;
        margin: 0 auto;
    }


    .intro .container {
        margin: 8%;
    }
}