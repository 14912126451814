.faq {
    min-height: 100vh;
    /* background-color: #ff4d4d; */
    background-color: var(--green1);
    padding: 5rem 0;
    text-align: left;
    color: white;
}

.faq .container {
    margin-top: 3rem;
}

.relative {
    position: relative;
}

.faq p {
    font-family: 'Inter';
    z-index: 10;
    font-size: 1rem !important;
    font-weight: 100;
    padding-bottom: 3rem;
}

/* this is scuffed but it'll do for now */
.sideleaf {
    position: absolute;
    width: 35%;
    height: auto;
    left: -280px;
    top: -18rem;
    z-index: 0;
}

@media screen and (max-width: 900px) {
    .sideleaf {
        visibility: hidden;
    }

    .faq .container {
        padding: 8%;
        margin-top: 1.2rem;
    }

    .faq p {
        padding-bottom: 1.2rem !important;
    }
}

#img {
    position: relative;
    width: 140%;
    align-self: center;
    right: 4rem;
}

.faq-header h1 {
    padding-bottom: 1rem;
    z-index: 10;
}

.faq-subtitle {
    font-family: 'Inter';
    z-index: 10;
}


#hello-email {
    text-decoration: none !important;
    color: white;
}

#sponsor-email {
    color: white !important;
    text-decoration: none !important;
}

@media screen and (max-width: 575px) {
    #video {
        width: 100%;
    }

    .faq {
        padding: 8%;
    }

    .faq-subtitle {
        font-size: 1rem !important;
    }
}

@media screen and (max-width: 534px) {
    .faq-header h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 426px) {
    .faq-header h1 {
        font-size: 1.8rem;
    }
}


@media screen and (max-width: 383px) {
    .faq-header h1 {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 342px) {
    .faq-header h1 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 320px) {
    .faq-header h1 {
        font-size: 1.4rem;
    }
}